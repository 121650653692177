var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:['pt-4', 'widget', 'overflow-hidden', { 'kpi-error': _vm.kpi.error }]},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card-heading,list-item-three-line,image,list-item"}}):[_c('header',{staticClass:"mb-3 px-4"},[_c('div',{staticClass:"widget__header"},[_c('h2',{staticClass:"widget__title mb-2 mr-4"},[_vm._v(" "+_vm._s(_vm.kpi.name)+" ")]),_vm._t("actions",function(){return [_c('div',{staticClass:"widget__actions"},[_vm._l((_vm.menuItems),function(ref,key){
var icon = ref.icon;
var onClick = ref.onClick;
return _c('CyButton',{key:key,attrs:{"icon-only":"","icon":icon,"theme":"grey","variant":"tertiary","sm":""},on:{"click":onClick}})}),_c('CyButton',{attrs:{"icon-only":"","icon":_vm.kpi.favorite ? 'star' : 'star_outline',"theme":"primary","variant":"tertiary","sm":"","loading":!_vm.canEdit || _vm.isSaving},on:{"click":function($event){return _vm.toggleFavorite(!_vm.kpi.favorite)}}}),_c('CyMenu',{attrs:{"bottom":"","left":"","offset-y":"","items":_vm.$static.dropdownMenuItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"variant":"tertiary","theme":"primary","icon":"more_horiz","icon-only":"","sm":""}},on))]}}])})],2)]}),_c('CyTooltip',{attrs:{"bottom":"","theme":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.kpi.error)?_c('CyButton',_vm._g({attrs:{"icon-only":"","icon":"error","theme":"error","variant":"tertiary","sm":""}},on)):_vm._e()]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.$t('kpis.anErrorOccurred')))}}),_c('div',[_vm._v(_vm._s(_vm.kpi.error))])])],2),_c('div',{staticClass:"widget__legend"},[(_vm.showJob)?[_c('div',{staticClass:"widget__legend-item"},[_c('CyAvatar',{staticClass:"mr-2",attrs:{"item":_vm.kpi.project,"sm":""}}),_c('div',{staticClass:"widget__legend-details"},[_c('label',{staticClass:"widget__label"},[_vm._v(_vm._s(_vm.$t('untranslated.pipeline')))]),_c('router-link',{attrs:{"to":{
                  name: 'envComponentPipeline',
                  params: {
                    orgCanonical: _vm.orgCanonical,
                    projectCanonical: _vm.kpi.project.canonical,
                    pipelineCanonical: _vm.kpi.pipeline_name,
                    envCanonical: _vm.kpi.environment_canonical,
                    componentCanonical: _vm.kpi.component_canonical,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.kpi.pipeline_name)+" ")])],1)],1),_c('v-icon',{staticClass:"widget__legend-icon",attrs:{"size":"18"}},[_vm._v(" keyboard_arrow_right ")]),_c('div',{staticClass:"widget__legend-item"},[_c('div',{staticClass:"widget__legend-details"},[_c('label',{staticClass:"widget__label"},[_vm._v(_vm._s(_vm.$t('untranslated.job')))]),_c('router-link',{attrs:{"to":{
                  name: 'jobs',
                  params: {
                    orgCanonical: _vm.orgCanonical,
                    projectCanonical: _vm.kpi.project.canonical,
                    pipelineCanonical: _vm.kpi.pipeline_name,
                    jobCanonical: _vm.kpi.job_name,
                    envCanonical: _vm.kpi.environment_canonical,
                    componentCanonical: _vm.kpi.component_canonical,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.kpi.job_name)+" ")])],1)])]:_vm._e()],2)]),_c('div',{staticClass:"widget__content px-4 pb-4"},[_vm._t("default",function(){return [_c('div',{staticClass:"widget__empty-state"},[_c('span',[_vm._v(_vm._s(_vm.$t('noData')))])])]})],2),(_vm.$slots.footer)?[_c('v-divider'),_c('footer',{staticClass:"widget__footer pa-4"},[_vm._t("footer")],2)]:_vm._e(),_c('CyFormsWidget',{key:_vm.kpi.updated_at,ref:"formDialog",staticClass:"d-none",attrs:{"project-canonical":_vm.kpi.project.canonical,"kpi":_vm.kpi}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }